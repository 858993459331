import { Link, graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"

import Layout from "../components/layout/layout"
import Pagination from "../components/pagination"
import Seo from "../components/seo"

const Header = ({ title }) => (
	<div className="pt-3 sm:flex sm:items-center sm:justify-between">
		<h3 className="text-4xl font-medium text-gray-900 text-center md:text-left">
			{title}
		</h3>
		<div className="flex flex-col items-center md:items-end mt-3 sm:mt-0 sm:ml-4 "></div>
	</div>
)

const InterestTopicListItem = ({
	item: { permalink, name, interestGroupId },
}) => {
	const linkUrl = `/interest-group/${permalink}`

	return (
		<div className="text-md text-lg lg:text-xl text-center font-semi-bold text-white">
			<Link to={linkUrl}>{name}</Link>
		</div>
	)
}

const InterestTopicList = ({
	pageContext: { currentPage, limit, numPages, route },
	data,
}) => {
	const pageChanged = pageNumber => {
		navigate(`/interest-topics/${pageNumber === 1 ? `` : pageNumber}`)
	}

	const items = data.allInterestGroup.nodes

	return (
		<Layout>
			<Seo title="Interest Topics" />
			<h1 className="text-neutral font-bold text-xl md:text-2xl lg:text-4xl text-center md:text-left px-2 md:px-20 lg:px-40 pt-4 lg:pt-8 md:text-2xl mb-1 md:mb-4">
				Interest Topics
			</h1>
			<div className="pt-5 pb-5 px-2 md:px-20 lg:px-40">
				<p>
					Below are a list of IPWEA NSW interest topics, click on the topic
					below to view relevent news, resources, training and events. Members
					can join different interest topics to personalise information shown in
					their IPWEA NSW portal.
				</p>
			</div>
			<div className="bg-gray-200 max-w-full py-8 md:py-16 xl:py-24 md:px-8 lg:px-24 xl-px-32">
				<ul
					role="list"
					className="text-white divide-y sm:divide-y-0 grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-4 md:gap-2 lg:gap-8 p-1 "
				>
					{items.map((item, idx) => (
						<li
							key={item.interestGroupId}
							className="bg-info min-h-32 md:h-60 flex flex-col justify-center items-center px-1 md:px-2 lg:4 xl:px-8 text-md text-white filter drop-shadow-lg px-4 py-4 sm:px-0 hover:opacity-80"
						>
							<InterestTopicListItem item={item} key={idx} />
						</li>
					))}
				</ul>
			</div>

			<Pagination
				onPageChange={pageChanged}
				pageCount={numPages}
				currentPage={currentPage}
				pageSize={limit}
			/>
		</Layout>
	)
}

export default InterestTopicList

export const pageQuery = graphql`
	query InterestTopicListQuery($skip: Int!, $limit: Int!) {
		allInterestGroup(
			filter: { isRTDFilter: { eq: false } }
			limit: $limit
			skip: $skip
		) {
			nodes {
				name
				permalink
				interestGroupId
			}
		}
	}
`
